:root{
    --grid-margin:-200px;
    --product-navs:none;
    --category-navs:none;
    --testimonial-navs:none;
    --users-navs:none;
    --entry-record:none;
    --category-update:none;
}
.boxing{
    display: flex;
    overflow: hidden;
    
}
* {
margin: 0;
padding: 0;

}

#menu{
    background-color: #111827;
    width: 200px;
    height: 100%vh;
    

    
}
#menu .logo{
    display: flex;
    align-items: center;
    color: #fff;
    padding: 30px 10px 0px;
}
#menu .logo img{
    max-width: 150px;
    margin-right: 15px;
}
#menu .items{
    margin-top: 40px;
    list-style: none;
    color: wheat;
}
#menu .items li{
list-style: none;
padding: 15px 0px;
color: white;
}

#menu .items .internal_link{
    list-style: none;
    text-decoration: none;
    padding: 15px 0px;
    color: white;
    }
    

#menu .items .inside-link li{
    color:white;
    text-align: center;
    background-color: rgb(13, 45, 88);
    transition: all 0.5s ease;
}

#menu .items .inside-link{
    transition: opacity 0.5s ease-in-out;   
    display: var(--product-navs);
  
}

#menu .items .inside-linkc li{
    color:white;
    text-align: center;
    background-color: rgb(13, 45, 88);
    transition: all 0.5s ease;
}

#menu .items .inside-linkc{
    transition: opacity 0.5s ease-in-out;   
    display: var(--category-navs);
  
}


#menu .items .inside-linkt li{
    color:white;
    text-align: center;
    background-color: rgb(13, 45, 88);
    transition: all 0.5s ease;
}

#menu .items .inside-linkt{
    transition: opacity 0.5s ease-in-out;   
    display: var(--testimonial-navs);
  
}
#menu .items .inside-linku li{
    color:white;
    text-align: center;
    background-color: rgb(13, 45, 88);
    transition: all 0.5s ease;
}

#menu .items .inside-linku{
    transition: opacity 0.5s ease-in-out;   
    display: var(--users-navs);
  
}
#menu .items li i{
    width: 30px;
    height: 30px;
    
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    margin: 0px 0px 0px 25px;
}
#menu .items li:hover{
    background-color: #253047;
    cursor: pointer;
    
}
/*#menu .items li:nth-child(1){
    border-left: 4px solid #fff;

}*/
#interface {
    width: 100%;
}
#interface .navigation{
    
    background-color: #253047;
    width: 100% ;
}
#interface .navigation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #253047;
    padding: 15px 30px;
    border-bottom: 3px solid #594ef7;
}

#interface .navigation  .search{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 02px 14px;
    border: 1px solid #d7dbe6;
    border-radius: 4px;
}

#interface .navigation  .search i{
margin-right: 10px;
color: white;
}

#interface .navigation .search i:nth-child(1){
    display: none;
}
#interface .navigation  .search input{
    background-color: transparent;
    border: none ;
    outline: none;
    color: white;

}
#interface .profile i{
    color: white;
    text-align: center;
    align-items: center;
    margin-right: 15px;
    justify-content: center;
}

@media screen and (max-width: 430px) {
    #menu{
  margin-left: var(--grid-margin);
  transition: 1s;
  z-index: 1;
    }
    #interface .navigation .search i:nth-child(1){
        display: block;
    }
    #interface .navigation .search input{
        display: none;
    }
    #interface .navigation .search i:nth-child(2){
        display: none;
    }

#content-section .content-box .form-box{
        width: 100%!important;
        margin-left: 0px!important;
    }
    
#content-section .content-box .form-box-product{
    width: 100%!important;
    margin-left: 0px!important;
}
  }

  #content-section .content-box{
    height: 100vh;
    background-color: rgb(236 238 246);
    ;
    padding: 30px 0px;
    overflow-y: scroll;
    

  }
  #content-section .content-box-list{
    height: 100vh;
    background-color: rgb(236 238 246);
    ;
    padding: 30px 50px;

  }
  #content-section .content-box-list {
    overflow: scroll;
  }
  #content-section .content-box-list .table-image img{
    max-height: 40px;
    max-width: 50px;
    margin-left: 3px;
    
  }

  #content-section .content-box .form-box{

  background-color: #fff;
  height: auto;
  width: 42%;
  display:inline-block;
  margin-left: 50PX;
  border: 1px solid white;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  }

  #content-section .content-box .form-box-product{
    background-color: #fff;
    height: 100px;
    padding: 10px 10px;
    width: 90%;
    display:inline-block;
    margin-left: 50PX;
    border: 1px solid white;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    }


  #content-section .content-box .form-box .form-heading{
    width: 100%;
    padding: 05px 10px;
    overflow: hidden;
    background-color:rgb(247, 247, 248);
  }
  #content-section .content-box .form-box .form-input{
    height: 100%;
    width: 100%;
    padding: 10px 10px;
    
  }

  #content-section .content-box .form-box .form-input span{
    display: inline-block;
    padding: 04px 0px;
    
  }
  #content-section .content-box .form-box .form-input span input[type="file"]{
    margin-left: 10px;
  }

  .form-btns{
    padding: 30px 50px;
  }
  .form-btnss{
    padding: 05px 30px;
  }
  .flexship{
    display: flex;
    gap: 0.6em;
    margin-top: 10px;
  }
  .form_error{
    color: red;
    text-align: center;
    align-items: center;
    font-weight: 500;
    text-transform: capitalize;
  }
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    align-items: center;
    justify-items: center;
    margin-left: 50%;
    margin-left: 50%;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .popup{
width: 400px;
z-index: 2;

box-shadow: 1px 2px 12px 3px black;
height: 200px;
background: #fff;
border-radius: 6px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
text-align: center;
padding: 30px 30px;
display: var(--entry-record);
color: #333;

  }
  .popup img{
    max-width: 40px;
  }
  .upgradepop{
    width: 400px;
z-index: 2;

box-shadow: 1px 2px 12px 3px black;
height: auto;
background: #fff;
border-radius: 6px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
text-align: center;
padding: 30px 30px;
display: var(--category-update);
color: #333;
  }